/*
 *   File : phoneInput.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Phone Input style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_input_box ~ label {
  position: absolute;
  left: 100px;
  top: 24px;
  font-weight: 600;
  @extend %font14;
  letter-spacing: 0.01em;
  color: $light_grey;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.e_input_box:focus ~ label {
  color: $light_grey;
  @extend %font12;
  -webkit-transform: translateY(-14px);
  transform: translateY(-14px) !important;
}

.PhoneInput {
  background: transparent;
  border: 1px solid $light_grey;
  background-color: transparent;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $white;
  padding: 20px;
  border-radius: 8px;
  padding: 19px 50px 19px 20px;
}
.PhoneInput--focus {
  border: 1px solid rgba(90, 105, 238, 1);
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
  background-color: transparent !important;
}

.PhoneInput {
  input {
    border: none !important;
    outline: none !important;
    background: transparent;
    font-weight: 600;
    color: $white;
    @extend %font14;
    &::placeholder {
      font-weight: 600;
      line-height: 18px;
      color: $light_grey;
      @extend %font12;
      letter-spacing: 0.25px;
    }
    &:focus {
      border: none !important;
      outline: none !important;
      label {
        color: $light_grey;
        @extend %font12;
        -webkit-transform: translateY(-14px);
        transform: translateY(-14px);
      }
    }
  }
}

.e_input_error {
  border: 1px solid $error;
}
.e_error_msg {
  @extend %font12;
  color: $error !important;
}
