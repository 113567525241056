/*
 *   File : edit.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Invest modal style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 04-01-2022
 */

@import "./../config.scss";

.e_edit_modal {
  .modal-dialog {
    transform: none;
    min-width: 585px;
  }
  .e_modal_content {
    background: rgba(6, 6, 6, 0.5);
backdrop-filter: blur(18px);
  }
  h6 {
    @extend %h5;
    color: $white;
    font-weight: 700 !important;
  }
  p {
    font-weight: 500;
    @extend %font12;
    line-height: 24px;
    color: $secondary_color;
  }
  .header {
    border: none !important;
  }
  .e_delete_text {
    font-weight: 400;
    @extend %font14;
    line-height: 24px;
    color: $secondary_color;
  }
  .textarea_e_textarea__3WFuM:focus {
    border: 1px solid transparent;
  }
  .textarea_e_textarea__3WFuM {
    padding: 32px 16px !important;
  }
  .e_modal_submit {
    border: none;
    color: $primary_color;
    font-weight: 700;
    background: none !important;
    &:after {
      background: none;
    }
  }

  .modal_success {
    background-color:#4A497A ;
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: $white;
      span {
        font-weight: 600;
      }
    }
  }
  .e_cancel {
    border: none;
    outline: none;
    background: $primary_color;
    padding: 18px 40px;
    color: $white;
    font-weight: 700;
    @extend %font14;
    line-height: 120%;
    position: relative;
    z-index: 0;
    border-radius: 8px;
    &:after {
      @include hover_animation();
      color: $white !important;
      background: #0b1fca;
      border-radius: 8px;
    }
    &:hover:after {
      opacity: 1;
      width: 100%;
      box-shadow: none !important;
    }
    span {
      z-index: 999;
      position: relative;
    }
  }
  .e_to_withdraw {
    @extend %font12;
    line-height: 16px;
    color: #6d6d6d;
  }
  .e_amount {
    font-weight: 700;
    @extend %font18;
    color: #6d6d6d;
  }
}

.e-save-btn {
  position: relative;
  line-height: 1.6rem;
  color: $primary_color;
  text-decoration: none;
  text-align: center;
}

.e-save-btn:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  top: 2.8rem;
  left: 2rem;
  background-color: $primary_color;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.e-save-btn:hover:before {
  visibility: visible;
  width: 45%;
}

.e-send-btn {
  position: relative;
  line-height: 1.6rem;
  color: $primary_color;
  text-decoration: none;
  text-align: center;
}

.e-send-btn:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  top: 2.8rem;
  left: 2rem;
  background-color: $primary_color;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.e-send-btn:hover:before {
  visibility: visible;
  // width: 68%;
}

.e_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.e_disabled_txt {
  opacity: 0.5;
  pointer-events: none;
  background: none !important;
  color: $primary_color;
  border: none !important;
}

.e_description {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #6d6d6d !important;
}
.e_profile_edit_sub_ittle {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #010101 !important;
}
