/*
 *   File : milestone-slider.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Slider Style
 *   Integrations : null
 *   Version : v1.0
*   Created : 29-08-2022
 */

@import "../config.scss";

.e_slider_wrapper {
  position: relative;
  background-color: $white!important;
  width: 549px !important;
  height: 4px;
  margin: auto;
  margin-bottom: 42px;
  .e_progress_bar {
    height: 4px;
    position: absolute;
    background-color: $primary_color;
    top: 0;
    left: 0;
    width: 0;
    -webkit-transition: background-color 0.5s;
    z-index: 1;
  }
  .e_step_one {
    width: 28px;
    height: 28px;
    border-radius: 100px;
    position: absolute;
    left: 0;
    top: -12px;
    text-align: center;
    color: $white;
    padding: 2px;
    -webkit-transition: background-color 0.5s;
    z-index: 2;
  }
  .e_step_two {
    width: 28px;
    height: 28px;
    border-radius: 100px;
    background-color: #4A497A;
    border: 1px solid $white;
    position: absolute;
    left: 50%;
    top: -12px;
    text-align: center;
    color: $white;
    padding: 2px;
    z-index: 2;
    -webkit-transition: background-color 0.5s;
  }
  .e_step_three {
    width: 28px;
    height: 28px;
    border-radius: 100px;
    background-color: #4A497A;
    border: 1px solid $white;
    position: absolute;
    left: 100%;
    top: -13px;
    text-align: center;
    color: $white;
    padding: 2px;
    z-index: 2;
  }
  .e_step_one_label {
    position: absolute;
    left: -6%;
    top: 18px;
  }
  .e_step_two_label {
    position: absolute;
    left: 41%;
    top: 18px;
  }
  .e_step_three_label {
    position: absolute;
    right: -17%;
    top: 18px;
  }
  .e_step {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $primary_color;
  }
  .e_content {
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.04em;
    color: $white;
    width: 75%;
  }
}
