/*
 *   File : portfolio-return.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Modal Style
 *   Integrations : null
 *   Version : v1.0
*   Created : 29-08-2022
 */

@import "./../config.scss";

.e_portfolio_return_modal {
  .e_modal_dialog {
    transition: ease-in-out max-width 0.6s!important;
    // max-width: 655px !important;
    
  }
  .e_modal_content {
    padding: 32px !important;
    border-radius: 14px !important;
    backdrop-filter: blur(18px);
    background: rgba(6, 6, 6, 0.5);
    color: $white!important;
    .e_modal_header {
      border-bottom: none !important;
      padding: 0 !important;
      h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: $white;
      }
     
    }
  }
  .e_loader_tex {
    font-style: normal;
    font-weight: 700;
    @extend %font14;
    line-height: 18px;
    color: $white;
  }
  .e_modal_title_img {
    height: 115px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.e-uploader {
  border: 1px dashed #a2a2a2;
  border-radius: 8px;
  padding: 36px 0px;
  cursor: pointer;
  // background: #323157;
  height: 225px;
  display: flex;
  justify-content: center;
  transition: .3s ease-in-out;
  &:hover {
    opacity: 0.7;
    transition: .3s ease-in-out;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $white;
    span {
      color: #bebebe;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
    }
  }
}

.e-preview {
  border: 1px solid #a2a2a2;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  // background: #323157;
  height: 225px;
  overflow-y: scroll;
  overflow-x: hidden;
  h6 {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: $white;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    color: $white;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .e-file-p {
    line-height: unset;
  }
  .e-border-bottom {
    border-bottom: 1px solid #f0f0f01a;;
  }
}
.e-delete {
  border: 1px solid $red !important;
  padding: 8px 16px;
  border-radius: 8px;
  color: $red;
  font-weight: 600;
  @extend %font14;
  line-height: 24px;
}
.e_loader_content {
  opacity: 0;
  animation: fadeIn  1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
