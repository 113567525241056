/*-- import supporting css*/
@import "./config.scss";
@import "./components/accordion.module.scss";
@import "./components/portfolio-return.module.scss";
@import "./components/phoneInput.module.scss";
@import './new-design.scss';

/*---------- Body ---------- */
body {
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  background-color: $body_color;
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top: -50px;
  //     right: -30px;
  //     width: 409px;
  //     height: 409px;
  //     border-radius: 900px;
  //     z-index: -1;
  //     background: linear-gradient(177.23deg, rgba(66, 62, 224, 0.4) -13.49%, rgba(129, 45, 226, 0.4) 109.75%);
  //     filter: blur(105.5px);
  //   }
  //   &::after {
  //     content: '';
  //     position: absolute;
  //     bottom: -100px;
  //     left: -10px;
  //     width: 309px;
  //     height: 350px;
  //     border-radius: 900px;
  //     z-index: -1;
  //     background: linear-gradient(177.23deg, rgba(66, 62, 224, 0.4) -13.49%, rgba(129, 45, 226, 0.4) 109.75%);
  //     filter: blur(90px);
  //   }
  &::-webkit-scrollbar {
    display: none;
  }
}

/*---------- Home ---------- */
.e-home {
  background: $home-bg;
  // margin-top: 50px;
  .e-home-container {
    padding-top: 125px;
    h1 {
      @extend %h1;
      padding-top: 50px;
      padding-right: 32px;
      span {
        color: $primary_color;
      }
    }
    p {
      font-weight: 400;
      @extend %font20;
      line-height: 30px;
      letter-spacing: 0.04em;
      color: $secondary_color;
      padding-top: 16px;
    }
    h5 {
      @extend %h5;
    }
    .e-broker-wraper {
      background: #f5fffc;
      box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.06);
      border-radius: 16px;
      margin-left: 0px;
      padding: 0 12px 0 0px;
      .e-after {
        position: relative;
        &::after {
          content: " ";
          height: 57px;
          width: 1.4px;
          background: #6d6d6d;
          display: block;
          position: absolute;
          top: 44px;
          right: 1px;
        }
      }
      .e-img-wrapper {
        margin: 26px 0;
      }
      img {
        cursor: pointer;
        // box-shadow: 0px 8px 26px rgba(56, 126, 209, 0.3);
      }
      .e-sync {
        position: relative;
        top: 60px;
      }
      .e-hover {
        transition: 0.4s ease-in-out;

        &:hover {
          transform: scale(1.01);
          transition: 0.4s ease-in-out;
          background-color: #defff6;
          border-radius: 8px;
        }
      }
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.04em;
        color: $black;
        text-align: center;
        margin-bottom: 0;
        padding-top: 10px;
        cursor: pointer;
      }
    }
  }
}
.e-slider-wrapper {
  background-color: #4A497A!important;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 25px 48px 17px 20px;
}
.myGif {
  width: 212px;
  height: 212px;
}
.e-border {
  // border: 1px solid #f0f0f0;
  // background-color: #4A497A!important;
  border-radius: 8px;
  height: 294px;
  overflow-y: scroll;
  overflow-x: visible;
}

.hidden {
  display: none !important;
}
.e-csv-table {
  // margin-top: 20px;
  thead th {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $white;
    padding-bottom: 12px;
    padding: 16px;
    white-space: nowrap;
  }
  tbody {
    tr {
      padding-bottom: 12px;
      td {
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        color: $white;
        padding: 4px 16px;
      }
    }
  }
  tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.e-returns-card {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 24px 0px 24px 0 !important;
  height: 452px;
  h6 {
    font-weight: 700 !important;
    @extend %font14;
  }
  .e-piechart-label-type {
    padding: 16px;
    p,
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $secondary_color;
      white-space: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.progress-bar {
  animation: reverse progress-bar-stripes 2s linear infinite;
}
.progress {
  animation: toRight 0.5s forwards;
  animation-delay: 1s;
  background-color: #e1e4ff !important;
  height: 8px;
  .progress-bar {
    // background-color: $primary_color !important;
    border-radius: 8px !important;
  }
}
.e-returns-card-register {
  background-image: url("../assets/images/returns/blur.png");
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 452px;
  background-size: 100%;
  img {
    width: 76px;
    height: 105px;
  }
  h6 {
    font-weight: 700;
  }
}
.e-active {
  background: $primary_color;
  border-radius: 4px;
  color: $white;
  @extend %font14;
  padding: 8px;
  width: 100%;
  margin-left: 10px;
  cursor: pointer;
  text-align: center;
}

.e-inactive {
  background: #f0f0f0;
  border-radius: 4px;
  color: $black;
  @extend %font14;
  padding: 8px;
  width: 100%;
  margin-left: 10px;
  cursor: pointer;
  text-align: center;
}

.e-bottom-sec {
  h6 {
    color: $secondary_color;
    @extend %font14;
    line-height: 100%;
    font-weight: 500;
    padding-top: 60px;
  }
}

.e-mintd-contact-sec {
  background: #ffffff;
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 34px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
}
.e-scroll {
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    background-color: #21dad7;
  }
}
.apexcharts-toolbar {
  display: none !important;
}
.apexcharts-yaxis {
  display: none !important;
}
/*drag and drop*/
.file-drop-area {
  position: absolute;
}
.file-input {
  position: relative;
  left: 0;
  top: 10px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
/* loader */
.e-btn-loader span {
  &:after {
    content: " .";
    font-family: "Epilogue";
    margin: 0;
    line-height: 100%;
    animation: dots 1s steps(1, end) infinite;
  }
}

@keyframes dots {
  0%,
  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    text-shadow: 0.2em 0;
  }

  75%,
  100% {
    text-shadow: 0.2em 0, 0.4em 0;
  }
}


// New Home
.e-new-home-container {
  .e-title {
    font-weight: 400;
    @extend %font56;
    line-height: 69px;
    color: $white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .e-colored{
        display: block;
        background: linear-gradient(263.24deg, #9130F4 21.05%, #4646F9 77.63%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 500;
      }
  }
  .e-subtitle{
    font-style: normal;
    font-weight: 400;
    @extend %font20;
    line-height: 26px;
    color: $white;
    letter-spacing: 0.04em;
    max-width: 400px;
  }
  .e-option-title {
    font-style: normal;
    font-weight: 700;
    @extend %font18;
    letter-spacing: 0.04em;
    line-height: 130%;  
    color: $white;
  }
  .e-home-btn {
    background-color: transparent;
    border: none;
    outline: none;
    transition: ease-in-out 0.5s;
      &:hover{
        transform: translateY(-5px);
      }
      img{
        width: 199px
      }
  }
  
}

.e-home-banner {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
}

// New returns
.e-new-returns-container {
  padding-bottom: 150px;
  .e-returns-page-card {
    background-color: #313065;
    position: relative;
    border-radius: 16px;
      .e-card-title {
        font-style: normal;
        font-weight: 700;
        @extend %font18;
        line-height: 22px;
        color: $white
      }
      label {
        @extend .e-card-title;
        font-size: 14px;
      }
      .e-switch {
        padding: 8px 20px;
        font-style: normal;
        font-weight: 500;
        @extend %font12;
        line-height: 15px;
        color: $secondary_color;
        background-color: $light_grey;
        border-radius: 4px;
        cursor: pointer;
       
      }
      .e-switch[disabled] {
        opacity: 0.7;
            cursor: default!important;
      }
      .e-active-switch {
        color: $white!important;
        background-color: $pink;
      }
      .e-text {
        font-weight: 200;
        @extend %font16;
        line-height: 26px;
        color: $white;
      }
  }

  .e-blured-card {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(23, 19, 74, 0.3);
    backdrop-filter: blur(10px);
    z-index: 5;
    border-radius: 16px;
      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $white;
      }
      button {
        width: 110px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 40px;
        font-weight: 700;
        font-size: 14px;
        color: $pink;
        outline: none;
        border: none;
      }
  }
  .e-how-invest-title {
    font-style: normal;
    font-weight: 600;
    @extend %font32;
    line-height: 26px;
    color: $white;
  }
  .e-invest-answer {
    font-style: normal;
    font-weight: 400;
    @extend %font14;
    line-height: 20px;
    color: $white;
  }
  .e-border-card {
    position: relative;
    background-image: url('../assets/images/Border.png');
    // border: 3px dashed $white;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 16px;
  }

  .e-fund-type{
    width: 100%;
  }
  .e-mintd-card-sub-title {
    font-style: normal;
    font-weight: 400;
    @extend %font14;
    line-height: 17px;
    color: #ffffff;
  }
  .e-title-mintd-card {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: $white;
  }
  .e-mintd-dashboard {
    width: 100%;
    height: 275px;
    object-fit: cover;
    border-radius: 16px 16px 0px 0px;
    // position: absolute;
    // bottom: 0;
    // left: -25px;
  }
  .e-mintd-banner-btn-wrapper {
    display: flex;
  }
}

.e-mintd-logo {
  cursor: pointer;
  transition: ease-in-out 0.5s;
    &:hover{
      transform: scale(0.8);
    }
}


// Returns graph
.e-bar-wrapper {
  height: 229px;
  // background-color: red;
  width: 100%;
  $classes: "mintd-bar"$pink 35%,
  "zerodha-bar" #BC7BFF,
  "nifty-bar" #7B90FF 18%;

  @each $class,
  $color,
  $left in $classes {
    .e-#{$class} {
      width: 80px;
      background-color: $color;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: height ease-in-out 0.5s;
    }

    h6 {
      position: absolute;
      font-style: normal;
      font-weight: 600;
      @extend %font12;
      line-height: 25px;
      color: $white;
      left: 0;
      right: 0;
      margin: auto;
      transition: bottom ease-in-out 0.5s;
    }

    .e-graph-legend {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $white;
    }
  }
}

.e-graph-bottom {
  border-bottom: 1px solid $white;
  height: 155px;
}

.e-dark-p-btn {
  padding: 20px 40px;
}
.e-dark-s-btn{
  background: transparent;
  border-radius: 38px;
  padding: 16px 40px;
  border: 1px solid $white;
  outline: none;
  color: $white;
  font-weight: 700;
  @extend %font14;
  line-height: 26px;
  color: $white;
}

.e-share-widget-wrapper {
  position: absolute;
  display: flex;
  right: 0;
  top: 35%;
  .e-social-wrapper {
    background: #313065;
    height: 223px;
    border-radius: 24px 0px 0px 24px ;
    transition: ease width 0.5s;
    ul{
      list-style: none;
      // padding: 0;
      li{
        margin-left: -7px;
        padding-top: 15px;
        transition: box-shadow .3s;
        transition: ease 0.5s;
        &:hover{
          transform: translateY(-2px);
          // box-shadow: 0 0 11px rgba(33,33,33,.2); 
        }
      }
    }
  }
  .e-share-widget-button {
    width: 37px;
    height: 45px;
    background: #313065;
    border-radius: 8px 0px 0px 8px;
    border: none;
    margin-top: 143px;
  }
}

.e-methodology-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 26px;
  color: $white;
}
.e-methodology-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: $white;
    ul {
      list-style: auto;
      padding-left: 15px;
    }
}
.e-view-methodology {
  padding: 14px 35px;
  font-size: 14px;
  line-height: 26px;
}

.css-1s2u09g-control, .css-1pahdxg-control {
  background-color: #171818!important;
  border: 1px solid rgba(255, 255, 255, 0.12)!important;
  border-radius: 8px!important;
  box-shadow: none!important;
}
.css-1okebmr-indicatorSeparator {
  opacity: 0;
}
.css-26l3qy-menu {
  background-color: #171818!important;
  border-radius: 8px!important;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.css-1n7v3ny-option, .css-9gakcf-option{
  background-color: #262626!important;
}
.css-qc6sy-singleValue {
    color: $white!important;
    font-weight: 500!important;
    font-size: 14px!important;
    line-height: 18px;
}
.e-number {
  width: 30px;
  height: 30px;
  background: #1E1E1E;
  border: 0.789474px solid #404040;
  border-radius: 78.1579px;
  font-weight: 600;
  font-size: 14.2105px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  font-family: $font-secondary;
  padding-top: 5px;
}