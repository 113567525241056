/*
 *   File : primary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Button Style
 *   Integrations :
 *   Version : v1.0
 */

 @import "./../config.scss";

 .e_p_btn{
   border: none;
   outline: none;
   background: #4400FF;
   padding: 14px 24px;
   color: $white;
   font-weight: 700;
   @extend %font14;
   line-height: 120%;
   position: relative;
   z-index: 0;
   border-radius: 4px;
   border: 1px solid #4400FF;
   &:after{
    @include hover_animation();
    color: $white!important;
    background: $primary_color; //#2a896e;
    border-radius: 4px;
   }
   &:hover:after{
     opacity: 1;
     width: 100%;
     box-shadow: none!important;
   }
   span {
     z-index: 999;
     position: relative;
   }
 }
 .e_disabled{
   opacity: 0.5;
   pointer-events: none;
 }
 
 