/*
 *   File : accrdion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Accordion Style
 *   Integrations : null
 *   Version : v1.0
*   Created : 29-08-2022
 */

@import "./../config.scss";

.accordion {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 8px;
  .accordion-item {
    border: none;
    background-color: #171818 !important;
    border-radius: 8px;
  }
  .accordion-button:not(.collapsed),
  .accordion-item:last-of-type .accordion-button.collapsed {
    background-color: #171818 !important;
    border-radius: 8px;
    box-shadow: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $white;
  }
  .accordion-button {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $white;
    background-color: $white !important;
    border-radius: 8px;
    // padding: 22px 16px;
   
  }
  .accordion-button:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
  .accordion-button:not(.collapsed)::after {
    content: '-';
    right: 17px;
    position: absolute;
    top: 27px!important;
    font-size: 20px;
    font-weight: 200;
    margin-top: -3px;
    background-image: none!important;
  }
  .accordion-button::after {
    content: '+';
    transition: none!important;
    right: 6px;
    position: absolute;
    top: 19px!important;
    font-size: 20px;
    font-weight: 200;
    background-image: none!important;
  }
  .accordion-button::before {
    content: '';
    position: absolute;
    border: 2px solid #ffffff;
    width: 20px;
    height: 20px;
    right: 11px;
    background-image: none !important;
    top: 21px;
    border-radius: 5px;
  }
  .accordion-body {
    padding-top: 0;
    .e-item-wrapper {
      background-color: #171818;
      padding: 16px;
      border-radius: 8px;
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        color: $white;
        margin-bottom: 4px;
        span {
          font-weight: 700;
          font-size: 15px;
        }
      }
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: $link;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
      ul {
        padding-left: 0;
        li {
          list-style: none;
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          color: $white;
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}
