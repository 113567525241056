@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Mintd Calculator
Version:    1.0.0
Created:    29-08-2022
Use:
-------------------------------------------------------------------*/

/*--- 1. Import Fonts ----*/

$font-primary: "Outfit";
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Outfit-ExtraBold.ttf) format("woff");
  font-weight: 800;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Outfit-Bold.ttf) format("woff");
  font-weight: 700;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Outfit-SemiBold.ttf) format("woff");
  font-weight: 600;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Outfit-Medium.ttf) format("woff");
  font-weight: 500;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Outfit-Regular.ttf) format("woff");
  font-weight: 400;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Outfit-Light.ttf) format("woff");
  font-weight: 300;
}


$font-secondary: "Montserrat";
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-ExtraBold.woff) format("woff");
  font-weight: 800;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-Bold.woff) format("woff");
  font-weight: 700;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-SemiBold.woff) format("woff");
  font-weight: 600;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-Medium.woff) format("woff");
  font-weight: 500;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-Regular.woff) format("woff");
  font-weight: 400;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Montserrat-woff/Montserrat-Light.woff) format("woff");
  font-weight: 300;
}


/* --- 2. Colors --- */
$primary_color:  #1329E6;//#9130F4; //#37b591; 
$secondary_color: #010101;
$body_color: #141414;;
$white: #ffffff;
$black: #000000;
$card_bg: #f5fffc;
$light_grey: #f0f0f0;
$link: #3f5eff;
$error: #ff523b;
$red: #de2020;
$toast_success_border: #00d1b1;
$toast_success_bg: #d4ece8f5;
$toast_success_color: #089487;
$toast_fail_bg: #f9e5e5;
$background_bg: #f6f9ff;
$green: #0fd99c;
$input_boder: #f0f0f0;
$input_text: #010101;
$input_placeholder: #6d6d6d;
$pink: #9130F4;

$home-bg: linear-gradient(193.95deg, #e9f5f2 -4.38%, #fcfffe 95.94%);

/* --- 3. Font --- */
%font10 {
  font-size: 0.625rem;
}
%font11 {
  font-size: 0.688rem;
}
%font12 {
  font-size: 0.75rem;
}
%font14 {
  font-size: 0.875rem;
}
%font16 {
  font-size: 1rem;
}
%font18 {
  font-size: 1.125rem;
}
%font20 {
  font-size: 1.25rem;
}
%font24 {
  font-size: 1.5rem;
}
%font32 {
  font-size: 2rem;
}
%font36 {
  font-size: 2.25rem;
}
%font48 {
  font-size: 3rem;
}
%font56 {
  font-size: 3.5rem;
}

/* --- 2. Global --- */
%h1 {
  @extend %font56;
  font-weight: 700;
  line-height: 68px;
}
%h3 {
  @extend %font32;
  font-weight: 700;
  line-height: 26px;
}
%h5 {
  @extend %font18;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.04em;
  color: $secondary_color;
  -webkit-font-smoothing: antialiased;
}
%h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: $secondary_color;
}

// .modal-dialog {
//   max-width: 636px !important;
//   border: none !important;
// }
// .modal-content {
//   padding: 15px !important;
//   border-radius: 8px !important;
// }
// .modal-header {
//   border-bottom: none !important;
// }
.btn-close {
  opacity: 10 !important;
  background-image: url("../assets/images/cta/close.svg");
  background-size: 20px;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none !important;
  opacity: 1;
}
.e-full-width {
  width: 100%;
}
.e-disable {
  opacity: 0.7;
  pointer-events: none;
}

.e-edit-modal {
  .modal-dialog {
    max-width: 636px !important;
  }
}

/* --- Scroll bar ---*/
::-webkit-scrollbar {
  border-radius: 8px;
  height: 4px;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 8x;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
}

/* ---3. Mixin styles ---*/

// primary button
@mixin hover_animation() {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all 0.35s;
  z-index: 1;
  opacity: 0;
}

.btn-close {
  opacity: 10 !important;
}
button:focus:not(:focus-visible) {
  outline: none !important;
}
.btn-close:focus {
  box-shadow: none !important;
}
.btn-close:hover {
  transform: scale(1.1);
}

