/*
 *   File : primaryIcon.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Link Text Button Style
 *   Integrations : null
 *   Version : v1.0
 */

@import "./../config.scss";

.e_secondary_icon {
  color: $primary_color!important;
  font-weight: 700;
  @extend %font14;
  outline: none !important;
  transition: all 0.35s;
  position: relative;
  background: #F6F9FF;
  border: 1px solid $primary_color;
  border-radius: 40px;
  padding: 12px 24px;
  // &:hover {
  //   text-decoration: underline;
  //   transition: 0.3s ease-in-out;
  // }
  span {
    // z-index: 99;
    position: relative;
  }
 img {
  padding-right: 18px;
 }
}
