@charset "utf-8";

/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1400px) {
  .e-dark-p-btn , .e-dark-s-btn {
    padding: 15px 30px;
  }
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1200px) {
  /*home*/
  .e-home .e-home-container .e-broker-wraper .e-after::after {
    // right: 12px;
  }
  .e-dark-p-btn , .e-dark-s-btn {
    // padding: 15px 10px;
  }
  
  .e-title {
    font-size: 64px!important;
  }
}

@media only screen and (max-width: 1199px) {
  .e-returns-container .e-margin-left {
    width: 100%;
  }
  .e-br-tag {
    display: none;
  }
  .e-returns-card-img {
    width: 100%;
  }
  .e-mintd-dashboard{
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 992px) {
  /*home*/
  .e-hero-banner {
    // display: none;
  }
  .e-home .e-home-container h1 {
    padding-top: 0px;
  }
  /*modal*/
  .milestone-slider_e_slider_wrapper__g6WJC {
    width: 434px !important;
  }
  .e-dark-p-btn , .e-dark-s-btn {
    padding: 15px 40px;
  }
  .e-mintd-dashboard{
    position: relative;
    bottom: 0;
    right: 0;
  }
}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {
  /*home*/
  .e-home .e-home-container h1 {
    font-size: 3.4rem;
  }
  .milestone-slider_e_slider_wrapper__13QIg {
    width: 343px !important;
  }
  .e-dark-p-btn , .e-dark-s-btn{
    width: 100%;
  }
  .e-mintd-banner-btn-wrapper{
    display: block!important;
  }
  .e-dark-p-btn {
    padding: 20px 40px;
  }
  .e-progress-wrapper {
    width: 100%!important;
  }
  .e-share-widget-wrapper {
    top: 60%;
  }
}


/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {
  /*home*/
  .e-home .e-home-container .e-broker-wraper .e-after::after {
    right: 8px;
  }
  .e-card-desktop {
    display: none;
  }
  .e-mobile-card {
    display: block;
  }
  /*home*/
  .milestone-slider_e_slider_wrapper__g6WJC {
    width: 2px !important;
    height: 375px !important;
  }
  .milestone-slider_e_slider_wrapper__g6WJC .milestone-slider_e_step_two__-Wh9Q {
    top: 182px;
    left: -14px;
  }
  .milestone-slider_e_slider_wrapper__g6WJC .milestone-slider_e_step_two_label__X7ns8 {
    top: 162px;
    left: 26px;
  }
  .milestone-slider_e_slider_wrapper__g6WJC .milestone-slider_e_step_three__xTi-H {
    bottom: 0;
    left: -12px;
    top: unset!important;
  }
  .milestone-slider_e_slider_wrapper__g6WJC .milestone-slider_e_step_three_label__dsf8D {
    bottom: -20px;
    left: 28px;
    top: unset!important;
  }
  .milestone-slider_e_slider_wrapper__g6WJC .milestone-slider_e_step_one__AtcYV {
    left: -12px;
  }
  .milestone-slider_e_slider_wrapper__g6WJC .milestone-slider_e_step_one_label__pEfPg {
    left: 23px;
    top: -21px;
  }
  .e-sticker {
    bottom: 0;
    height: 73px;
  }
  .e-returns-container .e-margin-left {
    width: 100%;
    height: max-content;
  }
  .e-graph-image {
    width: 100%;
  }
  .e-returns-modal {
    margin: auto;
  }
}

@media only screen and (max-width: 380px) {
  .milestone-slider_e_slider_wrapper__13QIg {
    width: 290px !important;
  }
}

/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {
}
