/*
 *   File : comparison-card.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Card Style
 *   Integrations : null
 *   Version : v1.0
*   Created : 29-08-2022
 */

@import "./../config.scss";

.e_card_wraper {
  background: #4A497A;
  border-radius: 8px;
  padding: 24px;;
  color: $white;
  h3 {
    font-weight: 700 !important;
  }
}
