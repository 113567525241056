/*
 *   File : toast.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Toast.module.scss
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_toast {
  position: fixed;
  bottom: 40px;
  right: 20px;
  width: 250px;
  padding: 15px 15px 15px 40px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 3px 4px 6px 1px rgba(0, 0, 0, 0.06);
  @extend %font14;
  background-size: 18px;
  background-position: 15px;
  background-repeat: no-repeat;
  z-index: 1099;
}
.e_success {
  @extend .e_toast;
  background-image: url("../../assets/images/note/check.png");
  border-color: $toast_success_border;
  background-color: $toast_success_bg;
  color: $toast_success_color;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e_fail {
  @extend .e_toast;
  background-image: url("../../assets/images/note/cancel.svg");
  border-color: red;
  background-color: $toast_fail_bg;
  overflow: hidden;
  text-overflow: ellipsis;
}
