
@import './config.scss';


.e-home-container {
        .e-video-wrapper {
            z-index: 1;
            width: 100%;
            height: 100%;
            video {
                width: 100%;
                top: 0;
                left: 0;
                height: 100%;
                object-fit: none;
                transform: scale(1.2);
                position: fixed;
            }
            &::after {
                content: '';
                position: fixed;
                width: 100%;
                height: 100%;
                transform: scale(1);
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.8);
            }
        }

        .e-page-content {
            position: relative;
            z-index: 2;
                .e-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 72px;
                    line-height: 91px;  
                    color: $white; 
                }
                .e-description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: rgba(255, 255, 255, 0.75);
                }
                .e-upload-btn {
                    background: #4400FF;
                    border-radius: 8px;
                    border: none;
                    outline: none;
                    padding: 18px 35px;
                    font-family: $font-secondary;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    color: $white;
                    line-height: 24px;
                    transition: ease-in-out 0.3s;
                        &:hover {
                            background-color: #3200BD;
                        }
                        img {
                            width: 20px;
                            vertical-align: text-top;
                        }
                }
                span {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 18px;
                    color: rgba(255, 255, 255, 0.5);
                    display: block;
                }
                ul {
                    margin-top: 140px;
                    li {
                        display: inline;
                    }
                }
        }
        
}

.e-returns-container {
    .e-card {
        background: #101010;
        border: 1px solid rgba(64, 64, 64, 0.5);
        border-radius: 10px;
        // position: relative;
            .e-card-title  {
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                color: $white;
            }
            .e-bar-wrapper {
                width: 352px;
                height: 203px;
                margin: auto;
                    .e-nifty-bar {
                        background: #EB7119;
                        border-radius: 6px 6px 0px 0px;
                        width: 60px;
                    }
                    .e-zerodha-bar {
                        background: #3877C6;
                        border-radius: 6px 6px 0px 0px;
                        width: 60px;
                    }
                    .e-mintd-bar {
                        background: #2B3EE9;
                        border-radius: 6px 6px 0px 0px;
                        width: 60px;
                    }
                    .e-graph-bottom {
                        border-bottom: 1px solid $white;
                        border-left: 1px solid $white;
                        height: 200px;
                        position: relative;
                            &::before {
                                content: '';
                                position: absolute;
                                width: 2px;
                                height: 2px;
                                top: 0;
                                left: -3.5px;
                                border: solid $white;
                                border-width: 0 1px 1px 0;
                                display: inline-block;
                                padding: 2.5px;
                                transform: rotate(-135deg);
                                -webkit-transform: rotate(-135deg);
                            }
                            &::after {
                                content: '';
                                position: absolute;
                                width: 2px;
                                height: 2px;
                                bottom: -4px;
                                right: 0;
                                border: solid $white;
                                border-width: 0 1px 1px 0;
                                display: inline-block;
                                padding: 2.5px;
                                transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                            }
                      }
            }
            .e-switch {
                background: #171717;
                border: 1px solid rgba(255, 255, 255, 0.06);
                border-radius: 4px;
                padding: 6px 12px;
                width: 39px;
                height: 27px;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: rgba(255, 255, 255, 0.75);
                cursor: pointer;
            }
            .e-active-switch {
                background: #4400FF;
                border-radius: 4px;
                border: 1px solid #4400FF;
                color: $white;
            }
            .e-switch[disabled] {
                opacity: 0.7;
                    cursor: default!important;
              }
            .e-note {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
            }
            .e-earnings {
                font-weight: 500;
                font-size: 20px;
                line-height: 25px;
                color: #FFFFFF;
                    span{
                        color: rgba(60, 188, 129, 1);
                    }
            }
            
    }
    .e-margin-left {
        width: 487px;
        height: 57px;
        margin: 0 auto;
    }
    .e-warning {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #E74A3B;
    }
    .e-primary-strip {
        background: $primary_color;
        border-radius: 10px;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $white;
        cursor: pointer;
        transition: ease-in-out 0.3s;
            &:hover {
                background-color: #3200BD;
            }
            span{ 
                font-weight: 500;
            }
    }
    .e-methodology {
        font-family: $font-secondary;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: $white;
        background: linear-gradient(114.74deg, #1329E6 0%, #1329E6 0.01%, #717FF0 101.07%);
        border-radius: 2.5px;
    }
    .e-how-to-invest {
        font-weight: 500;
        font-size: 54px;
        line-height: 66px;
        color: #FFFFFF;
        font-family: $font-secondary;
    }
    .e-answer {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        font-family: $font-secondary;
        color: rgba(255, 255, 255, 0.75);
    }
    .e-detail-image-ul {
        margin: o auto;
        position: relative;
        li {
            display: inline;
        }
    }
    .e-methodology-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        font-family: $font-secondary;
        color: $white;
    }

    .e-methodology-content {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 175%;
        font-family: $font-secondary;
        color: $white;
    }
    .e-graph-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        font-family: $font-secondary;
        color: $white;
    }

    .e-methodology-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 175%;
        font-family: $font-secondary;
        color: $white;
            a{
                color:rgba(113, 127, 240, 1)
            }
    }

    .e-returns-page-card {
        background: linear-gradient(114.74deg, #1329E6 0%, #1329E6 0.01%, #717FF0 101.07%);
        position: relative;
        border-radius: 24px;
          .e-mintd-card-sub-title {
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            color: #FFFFFF;
            font-family: $font-secondary;
          }
          .e-title-mintd-card {
            font-weight: 700;
            font-size: 36px;
            font-family: $font-secondary;
            line-height: 44px;
            color: $white
          }
          label {
            @extend .e-card-title;
            font-size: 14px;
          }
          .e-switch {
            padding: 8px 20px;
            font-style: normal;
            font-weight: 500;
            @extend %font12;
            line-height: 15px;
            color: $secondary_color;
            background-color: $light_grey;
            border-radius: 4px;
            cursor: pointer;
           
          }
          .e-switch[disabled] {
            opacity: 0.7;
                cursor: default!important;
          }
          .e-active-switch {
            color: $white!important;
            background-color: $pink;
          }
          .e-text {
            font-weight: 200;
            @extend %font16;
            line-height: 26px;
            color: $white;
          }
          .e-mintd-dashboard {
            width: 100%;
            height: 335px;
            // object-fit: cover;
            border-radius: 16px 16px 0px 0px;
            // position: absolute;
            // bottom: 0;
            // left: -25px;
          }
      }
      .e-dark-s-btn  {
        background: #FFFFFF;
        border-radius: 99px;
        border: 1px solid #FFFFFF;
        font-weight: 600;
        font-size: 20px;
        line-height: 16px;
        color: $primary_color;
        transition: ease-in-out 0.3s;
            &:hover {
                background-color: #ffffff96;
            }
      }
      .e-blured-card {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: transparent;
        backdrop-filter: blur(12px);
        z-index: 5;
        border-radius: 16px;
            .e-mintd-lock {
                margin-top: 110px;
            }
          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            color: $white;
          }
          button {
            font-weight: 600;
            font-family: $font-secondary;
            font-size: 18px;
          }
      }
      
}
.e-modal-upload {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    font-family: $font-secondary;
    padding: 10px 18px;
        img {
            margin-right: 10px;
            width: 16px;
            height: 16px;
        }
}

.e-modal-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    height: 31px;
    padding: 0 24px;
}

.e-sticker {
    position: absolute;
    bottom: 24px;
    left: 24px;
}

.e-loader-wrapper {
    background: rgba(6, 6, 6, 0.5);
    border: 1px solid rgba(64, 64, 64, 0.5);
    backdrop-filter: blur(18px);
    border-radius: 10px;
    width: 357px;
    opacity: 0;
    animation: fadeIn 1s forwards;
    // height: 379px;
}


.e-unmount-table {
    position: absolute;
    opacity: 1;
    left: 0;
    height: 294;
    animation: fadeOut 0.5s forwards;
}
.e-graph-label {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}
.e-modal-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.e-mobile-card {
    display: none;
}
@keyframes fadeOut {
    0% {
        opacity: 1;
        left: 0;
    }
    to {
        opacity: 0;
        left: 1000px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}