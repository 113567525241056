/*
 *   File : secondary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Secondary Button Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_s_btn {
  background: transparent;
  border: 1px solid #4400FF;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: 12px 24px;
  color: $white;
  font-weight: 700;
  @extend %font14;
  line-height: 120%;
  position: relative;
  z-index: 0;
  transition: all 0.35s;
  // &:after {
  //   color: $secondary_color !important;
  //   background: none;
  //   border: 1px solid $secondary_color;
  //   border-radius: 40px;
  //   height: 100%;
  //   position: absolute;
  //   content: "";
  //   top: 0px;
  //   left: 0;
  //   width: 0;
  //   transition: all 0.35s;
  //   z-index: 1;
  //   opacity: 0;
  // }
  // &:hover:after {
  //   opacity: 1;
  //   width: 100%;
  //   box-shadow: none !important;
  // }
  // span {
  //   z-index: 999;
  //   position: relative;
  // }
  &:hover {
    color: $primary_color;
    background: none;
    border: 1px solid $primary_color;
    border-radius: 4px;
    transition: all 0.35s;
  }
}
.e_disabled {
  opacity: 0.5;
  pointer-events: none;
}
