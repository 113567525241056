/*
 *   File : Header.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Header Style
 *   Integrations : null
 *   Version : v1.0
*   Created : 29-08-2022
 */

.e_header_wrap {
  position: fixed;
  width: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  padding: 24px 0;
  background: transparent;
  backdrop-filter: blur(35px);
  transition: 0.4s ease-in-out;

  .e_logo {
    width: 80px !important;
    height: 32px;
    transition: ease-in-out 0.3s;
    &:hover {
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      transform: scale(0.9);
    }
  }
}
